import type { LottiePlayer } from '@lottiefiles/lottie-player';
import { useEffect } from 'react';

interface LottieCompProps {
  src: LottiePlayer['src'];
  style?: Partial<LottiePlayer['style']>;
}

const LottieComp = ({ src, style = {} }: LottieCompProps) => {
  // NB: otherwise, will cause app to crash. see https://remix.run/docs/en/v1/guides/constraints#third-party-module-side-effects
  useEffect(() => {
    import('@lottiefiles/lottie-player');
  }, []);

  if (typeof document === 'undefined') return null;
  return (
    //@ts-expect-error dynamic import
    <lottie-player
      autoplay
      loop
      mode="normal"
      src={src}
      style={{
        ...{
          width: '100%',
          backgroundColor: 'transparent',
        },
        ...style,
      }}
    />
  );
};

export default LottieComp;
